import { useInitData } from "@telegram-apps/sdk-react";

const useUserInfo = () => {
  const initData = useInitData();
  return {
    username: (
      (initData?.user?.firstName || "") +
      " " +
      (initData?.user?.lastName || "")
    ).trim(),
  };
};

export default useUserInfo;
