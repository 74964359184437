import {
  forwardRef,
  type ComponentProps,
  type FC,
  type PropsWithChildren,
  ReactNode,
} from "react";
import Backdrop from "../backdrop";
import Fade from "../fade";
import Portal from "../portal";
import styles from "./dialog.module.css";
import useKeyDown from "@/hooks/use-key-down";
import type { ModalColorType, ResponsiveKeys } from "@/global/types";
import useLockBodyScroll from "@/hooks/use-lock-body-scroll";
import classNames from "classnames";
import BorderGradient from "../border-gradient";
import Image from "../image";
import { IMAGES } from "@/constants";

export interface DialogProps {
  /**
   * If `true`, the component is shown.
   */
  open: boolean;
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
  /**
   * If `true` onClose function does'nt fire on click on backdrop
   */
  disableBackdropClose?: boolean;
  /**
   * If `true` onClose function does'nt fire on escape key down
   */
  disableEscapeKeyDown?: boolean;
  /**
   * Determine the max-width of the dialog.
   * The dialog width grows with the size of the screen.
   * @default 'xs'
   */
  maxWidth?: ResponsiveKeys | number;
  /**
   * If `true`, the Backdrop interface is blur.
   */
  backdropBlur?: ComponentProps<typeof Backdrop>["blur"];
  /**
   * If `true`, the dialog is full screen.
   */
  fullScreen?: boolean;
  /**
   * seedClassName applied to seed container.
   */
  seedClassName?: string;
  outerClassName?: string;
  maxSeedHeight?: boolean;
  className?: string;
  withoutCloseIcon?: boolean;
  color?: ModalColorType;
  customChildren?: ReactNode;
}

const modalColor: Record<
  ModalColorType,
  { seedColorClassName: string; outerColorClassName: string }
> = {
  blue: {
    seedColorClassName:
      "bg-blue-750 shadow-blue-750 bg-gradient-to-b from-blue-800 to-blue-750",
    outerColorClassName: "shadow-blue-750",
  },
  purple: {
    seedColorClassName:
      "bg-purple-700 shadow-purple-300 bg-gradient-to-tr from-purple-400 to-purple-700",
    outerColorClassName: "shadow-purple-700 from-purple-200 to-purple-300",
  },
};

export const ModalSeed: FC<
  PropsWithChildren<{
    onClose: () => void;
  }>
> = ({ onClose, children }) => {
  // Using keyboard escape button to close modal
  useKeyDown("Escape", () => {
    onClose();
  });

  // Lock body scroll behavior
  useLockBodyScroll();

  return children;
};

// eslint-disable-next-line react/display-name
const Dialog = forwardRef<HTMLDivElement, PropsWithChildren<DialogProps>>(
  (
    {
      open,
      onClose,
      maxWidth,
      className,
      disableBackdropClose,
      disableEscapeKeyDown,
      backdropBlur = false,
      fullScreen,
      seedClassName,
      outerClassName,
      maxSeedHeight,
      withoutCloseIcon = false,
      children,
      color = "blue",
      customChildren,
    },
    ref
  ) => {
    const { seedColorClassName, outerColorClassName } = modalColor[color];
    const seedProps = { onClose: disableEscapeKeyDown ? () => {} : onClose };
    return (
      <Portal
        container={document.querySelector("#language-wrapper") || undefined}
      >
        <Fade
          tagName="div"
          className="absolute top-0 left-0 right-0 bottom-0 z-[2000]"
          in={open}
          duration={300}
        >
          <Backdrop
            blur={backdropBlur}
            onClick={() => {
              if (!disableBackdropClose) {
                onClose();
              }
            }}
          />
          {customChildren ? (
            <ModalSeed {...seedProps}>{customChildren}</ModalSeed>
          ) : (
            <div
              className={classNames(
                "h-full outline-0 flex items-center justify-center",
                styles.container
              )}
            >
              <div
                className={classNames(
                  "relative w-full flex items-center justify-center flex-col",
                  fullScreen
                    ? "h-full"
                    : [
                        maxSeedHeight
                          ? "h-[calc(100%-48px)]"
                          : "max-h-[calc(100%-48px)]",
                        "m-6",
                      ],
                  className
                )}
                style={{
                  maxWidth,
                }}
              >
                {/* Content */}
                <BorderGradient
                  outerClassName={classNames(
                    "p-2 grow w-full max-w-2xl mx-auto rounded-[2rem] box-shadow-inner-0-9-4 relative",
                    outerClassName,
                    outerColorClassName
                  )}
                  className={classNames(
                    "overflow-y-auto rounded-3xl box-shadow-inner-0-0-md",
                    seedClassName,
                    seedColorClassName
                  )}
                  ref={(node) => {
                    if (typeof ref === "function") {
                      ref(node);
                    } else if (ref) {
                      ref.current = node;
                    }
                  }}
                >
                  {!withoutCloseIcon && (
                    <Image
                      src={IMAGES.BTN_CLOSE}
                      alt="close"
                      className="w-7 absolute top-0 ltr:right-0 ltr:translate-x-1 -translate-y-1 rtl:left-0 rtl:-translate-x-1 cursor-pointer !pointer-events-auto z-10"
                      onClick={onClose}
                    />
                  )}
                  <ModalSeed {...seedProps}>{children}</ModalSeed>
                </BorderGradient>
              </div>
            </div>
          )}
        </Fade>
      </Portal>
    );
  }
);

export default Dialog;
