const paths = {
  home: "/",
  quest: "/quest",
  shop: "/shop",
  wallet: {
    index: "/wallet",
    deposit: "/wallet/deposit",
    depositStep2: "/wallet/deposit/step2/:id",
    withdraw: "/wallet/withdraw",
  },
  predictPro: {
    index: "/predict-pro",
    matches: "/predict-pro/matches",
    timeIsUp: "/predict-pro/time-is-up",
    submit: "/predict-pro/submit",
    nickName: "/predict-pro/nick-name",
    reward: "/predict-pro/reward",
    submission: "/predict-pro/submission/:id",
  },
  friends: "/friends",
  dailyReward: "/daily-reward",
  journeys: {
    index: "/journeys",
    matches: "/journeys/matches",
    submit: "/journeys/submit",
    submission: "/journeys/submission/:id",
    timeIsUp: "/journeys/time-is-up",
    onboarding: "/journeys/onboarding",
  },
  replace: {
    select: "/replace/select/:id",
    predict: "/replace/predict",
  },
};

export default paths;

export const isValidInternalPath = (
  searchString?: string,
  obj: any = paths
): boolean => {
  if (!searchString) return false;
  for (const key in obj) {
    if (typeof obj[key] === "string") {
      if (obj[key] === searchString) {
        return true;
      }
    } else if (typeof obj[key] === "object") {
      if (isValidInternalPath(searchString, obj[key])) {
        return true;
      }
    }
  }
  return false;
};
