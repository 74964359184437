import Dialog from "@/components/common/dialog";
import Image from "@/components/common/image";
import Link from "@/components/common/link";
import UserMenu from "@/components/common/user-menu";
import { UserMenuContext } from "@/components/common/user-menu/context";
import { AddFriendIcon, BarsIcon } from "@/components/icons";
import { IMAGES } from "@/constants";
import useUserInfo from "@/hooks/use-user-info";
import paths from "@/navigation/paths";
import { FC, useState } from "react";

const Profile: FC = () => {
  const { username } = useUserInfo();
  const [openUserModal, setOpenUserModal] = useState<boolean>(false);
  return (
    <>
      <Dialog
        open={openUserModal}
        onClose={() => setOpenUserModal(false)}
        seedClassName="py-6 px-4"
      >
        <UserMenuContext.Provider
          value={{ closeModal: () => setOpenUserModal(false) }}
        >
          <UserMenu />
        </UserMenuContext.Provider>
      </Dialog>
      <div className="flex gap-2.5 mb-2.5 animate-fade-in">
        <div className="flex items-center justify-between p-1 overflow-hidden relative rounded-xl border border-blue-700 bg-gradient-to-b from-blue-500 to-blue-750 box-shadow-0-3 shadow-blue-850 grow">
          <div className="flex items-center gap-2.5 overflow-hidden">
            <Image
              src={IMAGES.PROFILE_ICON}
              alt="profile"
              className="w-8 h-8"
            />
            <p className="font-bold text-shadow-0-3-blue-900 grow truncate text-stroke-md-blue-850">
              {username}
            </p>
          </div>
          <div
            className="shrink-0 w-8 h-8 border border-blue-300 bg-blue-600 rounded-lg flex justify-center items-center"
            onClick={() => setOpenUserModal(true)}
          >
            <BarsIcon />
          </div>
        </div>
        <Link
          to={paths.friends}
          className="shrink-0 w-10 shadow-icon border border-green-200 bg-gradient-to-b from-green-100 via-green-300 via-32.5% to-green-500 rounded-xl flex justify-center items-center text-green-900 box-shadow-0-3 shadow-green-700"
        >
          <AddFriendIcon />
        </Link>
      </div>
    </>
  );
};

export default Profile;
