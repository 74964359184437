import { useInitData } from "@telegram-apps/sdk-react";
import locales from "..";
import { usePersistData } from "@/store/persist-store";

const useLocale = () => {
  const initData = useInitData();
  const { language } = usePersistData();
  const locale = language || (initData as any)?.initData?.user?.languageCode;
  return (
    locales.find((l) => l.id === locale) || locales.find((l) => l.default)!
  );
};

export default useLocale;
