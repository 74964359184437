import useLocale from "@/i18n/hooks/use-locale";
import { createQueryString } from "@/utils/url";
import useAuth from "../../../hooks/use-auth";
import { ERROR_STATUS } from "@/constants";
import { useRef } from "react";
import endpoints from "../endpoints";

const methods = ["post", "patch", "get", "put", "delete"] as const;

const backendURL = 'https://pbackend.ledoso.com' as string;

type CallType = <T = any>(
  url: string,
  reqInit?: Omit<RequestInit, "body"> & {
    params?: any;
    body?: any;
  },
  customAccessToken?: string
) => Promise<T>;

const createCallWithMethods = (call: CallType) =>
  methods.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: (
        url: Parameters<typeof call>[0],
        reqInit?: Parameters<typeof call>[1]
      ) => call(url, { ...(reqInit || {}), method: curr.toUpperCase() }),
    }),
    {}
  ) as Record<(typeof methods)[number], typeof call>;

export const useCall = () => {
  const { id: locale } = useLocale();
  const { getStoredTokens, internalLogin, internalLogout } = useAuth();
  const isRefetchAccessTokenMode = useRef<boolean>(false);

  const call: CallType = (url, reqInit, customAccessToken) =>
    new Promise(async (resolve, reject) => {
      const { headers, params, body, ...restReqInit } = reqInit || {};

      const isFormData = body instanceof FormData;

      const searchParams = params
        ? `?${typeof params === "string" ? params : createQueryString(params)}`
        : "";

      const reqHeaders: HeadersInit = {
        Authorization: `Bearer ${
          customAccessToken || getStoredTokens()?.accessToken
        }`,
        "x-custom-lang": locale,
      };

      if (!isFormData)
        reqHeaders["content-type"] = "application/json; charset=utf-8";

      Object.entries(headers || {}).forEach(([key, val]) => {
        reqHeaders[key] = val;
      });

      try {
        const response = await fetch(backendURL + url + searchParams, {
          headers: reqHeaders,
          body: body ? (isFormData ? body : JSON.stringify(body)) : undefined,
          ...restReqInit,
        });

        const responseBody = await (response.headers
          .get("content-type")
          ?.includes("json")
          ? response.json()
          : response.text());

        if (response.ok) {
          isRefetchAccessTokenMode.current = false;
          resolve(responseBody);
        } else {
          if (response.status === ERROR_STATUS.UNAUTHORIZE) {
            if (!isRefetchAccessTokenMode.current) {
              const refreshTokenResponse = await fetch(
                backendURL + endpoints.auth.refreshToken,
                {
                  method: "POST",
                  headers: {
                    "content-type": "application/json; charset=utf-8",
                    Authorization: `Bearer ${getStoredTokens()?.refreshToken}`,
                  },
                }
              );
              if (refreshTokenResponse.ok) {
                const refreshTokenResponseBody =
                  await refreshTokenResponse.json();
                internalLogin({
                  refreshToken: refreshTokenResponseBody.refreshToken,
                  accessToken: refreshTokenResponseBody.token,
                });
                const retryResponse = await call(
                  url,
                  reqInit,
                  refreshTokenResponseBody.token
                );
                resolve(retryResponse);
                return retryResponse;
              } else {
                internalLogout();
                reject({ ...responseBody, status: response.status });
              }
            } else {
              internalLogout();
              reject({ ...responseBody, status: response.status });
            }
            isRefetchAccessTokenMode.current = true;
          }
          reject(responseBody);
        }
        return responseBody;
      } catch (err) {
        reject(err);
      }
    });

  return createCallWithMethods(call);
};
