export enum PredictDifficulties {
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard",
  VERY_HARD = "very-hard",
}
export enum MatchStatus {
  NotStarted = "not started",
  Live = "live",
  Finished = "finished",
  Pending = "pending",
}
export enum TaskStatus {
  NOT_STARTED = "not_started",
  CONFIRMED = "confirmed",
  STARTED = "started",
}
export enum TaskActionType {
  InviteUrl = "invite_url",
  ExternalLink = "external_link",
  Check = "check",
  Redirect = "redirect",
}
export enum TaskActionFieldType {
  Username = "username",
  Email = "email",
}
export enum WalletStatuses {
  DISCONNECTED = 1,
  CONNECTED = 2,
}
export enum TransactionStatus {
  Completed = "completed",
  Rejected = "rejected",
  Pending = "pending",
}
export enum TransactionType {
  Deposit = "deposit",
  Withdraw = "withdraw",
  Exchange = "exchange",
  Purchase = "purchase",
  Transaction = "transaction",
}
export enum TransactionNetworkType {
  Ethereum = "ethereum",
  BinanceSmartChain = "binance-smart-chain",
  Polygon = "polygon",
  Avalanche = "avalanche",
  Tron = "tron",
  Ton = "ton",
}
export enum BundleModelTypes {
  PURCHASE = "purchase",
  REWARD = "reward",
  GIVEAWAY = "giveaway",
  GIFT_CARD = "gift-card",
}
export enum BundleCategoryColorEnum {
  DarkBlue = "dark_blue",
  LightBlue = "light_blue",
  Green = "green",
  Pink = "pink",
  Purple = "purple",
  Yellow = "yellow",
}
export enum BundleCategoryDisplayTypes {
  GRID = "grid",
  SLIDER = "slider",
}
export enum AssetUnit {
  USD = "usd",
  GEM = "gem",
  TPPOG = "tppog",
  PREDI = "predi",
}
export enum LastMatchesType {
  WIN = "w",
  DRAW = "d",
  LOOSE = "l",
}
export enum SubmissionResult {
  HOME = "home",
  DRAW = "draw",
  AWAY = "away",
}

export enum StepStatus {
  PASSED = "PASSED",
  FAILED = "FAILED",
  LOCK = "LOCK",
  START = "START",
  LIVE = "LIVE",
  PENDING = "PENDING",
  NOT_STARTED = "NOT_STARTED",
}

export enum CurrentStepStatus {
  START = "start",
  WAITING = "waiting",
  SUBMITTED = "submitted",
  LIVE = "live",
  PEND = "pend",
  FAILED = "failed",
  WON = "won",
}

export enum SubmissionStatus {
  WAITING = "waiting",
  SUBMITTED = "submitted",
  LIVE = "live",
  PEND = "pend",
  FAILED = "failed",
  WON = "won",
}

export enum ProSubmissionStatus {
  NOT_STARTED = "Not Started",
  FINISHED = "Finished",
  LIVE = "live",
  PEND = "pend",
}

export enum MatchStatusesEnum {
  NotStarted = "not_started",
  Live = "live",
  Finished = "finished",
  Canceled = "canceled",
}

export enum MatchStatesEnum {
  NotStarted = "not_started",
  FirstHalf = "first_half",
  SecondHalf = "second_half",
  HalfTime = "half_time",
  Finished = "finished",
  OverTime = "overtime",
  OvertimeDeprecated = "overtime_deprecated",
  PenaltyShootOut = "penalty_shoot_out",
  Delay = "delay",
  Pending = "pending",
  CutInHalf = "cut_in_half",
  Cancel = "cancel",
  Interrupted = "interrupted",
}

export enum SubmissionItemStateEnum {
  WAITING = "waiting",
  NOT_STARTED = "not_started",
  LIVE = "live",
  PENDED = "pended",
  FINISHED = "finished",
}

export enum TapOnboardingState {
  Step1,
  Step2,
  Complete,
}

export enum EventNames {
  TapPurchaseCreated = "tapPurchase.created",
  SubmissionUpdated = "submission.updated",
  SubmissionProFirstUnclaimed = "submission.pro.firstUnclaimed",
  LeaderBoardFirstUnclaimed = "leader-board.firstUnclaimed",
  LeaderBoardUpdated = "leader-board.updated",
}

export enum DailyRewardStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
  CLAIMED = "claimed",
}
