import { useIntegration } from "@telegram-apps/react-router-integration";
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  postEvent,
  useMiniApp,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react";
import { type FC, useEffect, useMemo, useState, Suspense } from "react";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { routes } from "@/navigation/routes.tsx";
import MainLayout from "./layout/main";
import SplashScreen from "@/components/common/splash-screen";
import { useTonConnectUI } from "@tonconnect/ui-react";
import useSplashScreen from "@/components/common/splash-screen/use-splash-screen";
import useApi, { CheckProofBodyType } from "@/helpers/api/hooks/use-api";
import { useData } from "@/store/store";
import { IntlProvider } from "react-intl";
import useLocale from "@/i18n/hooks/use-locale";
import FixedSpinner from "@/components/common/spinner/fiexed";
import SessionExpired from "@/components/common/session-expired";
import SSEProvider from "@/components/common/sse";
import { showErrorMessage } from "@/utils/string";
import NetworkStatusWarning from "@/components/common/network-error-modal";
import RotateWarning from "@/components/common/rotate-warning";

export const App: FC = () => {
  const [canRunApp, setCanRunApp] = useState<boolean>(false);
  const miniApp = useMiniApp();
  const { id: locale } = useLocale();
  const themeParams = useThemeParams();
  const viewport = useViewport();
  const [tonConnectUI] = useTonConnectUI();
  const splashItems = useSplashScreen();
  const { setIsCheckingProof, connectWalletInfo, isSessionExpired } = useData();
  const { checkProof } = useApi();
  const { id, messages, direction } = useLocale();

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  useEffect(() => {
    postEvent("web_app_set_header_color", { color: "#001430" });
    postEvent("web_app_expand");
  }, []);

  // Create new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  useEffect(() => {
    tonConnectUI.onStatusChange((wallet) => {
      const tonProof = wallet?.connectItems?.tonProof;

      // ton connect generates a proof based on the domain and checks with the proof from the server
      // if the proof is valid, then the wallet is connected
      // the provided domain which is localhost should match the expected domain from the server which it does not when developing locally
      // so we provide the domain manually in development mode
      const devProofDomain = {
        domain: {
          value: "stagetma.predipie.com",
          lengthBytes: 21,
        },
      };

      if (tonProof && "proof" in tonProof) {
        const reqBody: CheckProofBodyType = {
          address: wallet.account.address,
          network: wallet.account.chain,
          proof: {
            ...tonProof.proof,
            state_init: wallet.account.walletStateInit,
            ...(import.meta.env.DEV && devProofDomain),
          },
          metaData: {},
        };
        setIsCheckingProof(true);
        checkProof(reqBody)
          .then(connectWalletInfo)
          .catch((e) => {
            toast.error(showErrorMessage(e.message), {
              position: "top-center",
              duration: 5000,
            });
            tonConnectUI.disconnect();
          })
          .finally(() => setIsCheckingProof(false));
      }
    });
  }, []);

  useEffect(() => {
    if (!splashItems.some((item) => !item)) {
      setTimeout(() => setCanRunApp(true), 1000 / splashItems.length);
    }
  }, [splashItems]);

  const appContent = () => {
    if (isSessionExpired) return <SessionExpired />;
    else if (canRunApp)
      return (
        <MainLayout>
          <SSEProvider>
            <Toaster />
            <Suspense fallback={<FixedSpinner />}>
              <Routes>
                {routes.map(({ sub, ...route }, i) => (
                  <Route key={`route-${i}`} {...route}>
                    {sub?.map((subRoute, subIndex) => (
                      <Route key={`sub-route-${subIndex}`} {...subRoute} />
                    ))}
                  </Route>
                ))}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Suspense>
          </SSEProvider>
        </MainLayout>
      );
    return <SplashScreen />;
  };

  return (
    <IntlProvider messages={messages} locale={id}>
      <div
        className={direction}
        dir={direction}
        lang={locale}
        id="language-wrapper"
      >
        <Router location={location} navigator={reactNavigator}>
          {appContent()}
        </Router>
        <NetworkStatusWarning />
        <RotateWarning />
      </div>
    </IntlProvider>
  );
};
