import { createElement } from "react";
import { SubmissionResult } from "./global/enum";
import { FormattedMessage } from "react-intl";

export const IMAGES = {
  AIRDROP_ICON: "/images/airdrop-icon.png",
  GOLDEN_BALL: "/images/golden-ball.png",
  BOY_AVATAR: "/images/boy-avatar.png",
  GOLD_COIN: "/images/gold-coin.png",
  SILVER_COIN: "/images/silver-coin.png",
  BRONZE_COIN: "/images/bronze-coin.png",
  TAP_IMAGE: "/images/tap-image.png",
  ALERT: "/images/alert.png",
  EMPTY_LIST: "/images/empty-list.png",
  SIMPLE_TAP: "/images/simple-tap.png",
  TAP_WITH_NUMBERS: "/images/tap-with-numbers.png",
  BALL_ERROR: "/images/ball-error.png",
  PREDI_BOY: "/images/predi-boy.png",
  REPAIRS: "/images/repairs.png",
  COIN_ICON: "/images/coin-icon.png",
  GEM_ICON: "/images/gem-icon.png",
  PREDI_ICON: "/images/predi-icon.png",
  PROFILE_ICON: "/images/profile-avatar.png",
  SHOP_ICON: "/images/shop-icon.png",
  QUEST_ICON: "/images/quest-icon.png",
  HOME_ICON: "/images/home-icon.png",
  PREDICT_PRO_ICON: "/images/predict-pro-icon.png",
  WALLET_ICON: "/images/wallet-icon.png",
  HOME_SCREEN_STADIUM: "/images/home-stadium.jpg",
  START_TOOLTIP: "/images/start-tooltip.png",
  PASSED_TOOLTIP: "/images/passed-tooltip.png",
  FAILED_TOOLTIP: "/images/failed-tooltip.png",
  LOCK_TOOLTIP: "/images/locked-tooltip.png",
  PENDING_TOOLTIP: "/images/pending-tooltip.png",
  LIVE_TOOLTIP: "/images/live-tooltip.png",
  BLUE_BALL: "/images/blue-ball.png",
  GATE: "/images/gate.png",
  PITCH: "/images/pitch.png",
  REWARD_BG: "/images/reward-bg.png",
  REWARD_AMOUNT_BG: "/images/reward-amount-bg.png",
  HOME_SCREEN_STADIUM_LARGE: "/images/home-stadium-large.jpg",
  THINKING_CLOUD: "/images/thinking-cloud.png",
  LOCK_ICON: "/images/lock-icon.png",
  PREDICT_PRO_BANNER: "/images/predict-pro-banner.jpg",
  FOOT_STEP: "/images/foot-step.png",
  YELLOW_TROPHY: "/images/yellow-trophy.png",
  BOX_PREDI: "/images/box-predi.png",
  TIMER: "/images/timer.png",
  CALENDAR: "/images/calendar.png",
  PURPLE_TIME: "/images/purple-time.png",
  FILLED_CHECK: "/images/filled-check.png",
  FILLED_FAILED: "/images/filled-failed.png",
  FILLED_START: "/images/filled-start.png",
  WALLET: "/images/wallet.png",
  PREDI: "/images/predi.png",
  BTN_CLOSE: "/images/btn-close.png",
  INSUFFICIENT_PREDI: "/images/insufficient-predi.png",
  FILLED_BLUE_CHECK: "/images/filled-blue-check.png",
  UNION: "/images/union.png",
  REWARD_BOX: "/images/reward-box.png",
  DEPOSIT_WALLET: "/images/deposit-wallet.png",
  WITHDRAW_WALLET: "/images/withdraw-wallet.png",
  SHOP_BG: "/images/shop-bg.png",
  SHOP_SKY_BLUE_UNION: "/images/shop-sky-blue-union.png",
  SHOP_GREEN_UNION: "/images/shop-green-union.png",
  SHOP_3_IN_1_PURPLE_BG: "/images/shop-3-in-1-purple-bg.png",
  NOT_STARTED_TOOLTIP: "/images/not-started-tooltip.png",
  NO_TRANSACTIONS: "/images/no-transactions.png",
  NO_TASKS: "/images/no-tasks.png",
  COMING_SOON: "/images/coming-soon.jpg",
  CLOCK_ICON: "/images/clock-icon.png",
  CHECK_LIST: "/images/check-list.png",
  FILLED_INFO: "/images/filled-Info.png",
  DATA_CARD_INVENTORY_BANNER: "/images/data-card-inventory-banner.jpg",
  TOOL_CARD_INVENTORY_BANNER: "/images/tool-card-inventory-banner.jpg",
  SUBMISSION_WON_BANNER: "/images/submission-won-banner.jpg",
  SUBMISSION_FAILED_BANNER: "/images/submission-failed-banner.jpg",
  SUBMISSION_WAITING_BANNER: "/images/submission-waiting-banner.jpg",
  SUBMISSION_LIVE_BANNER: "/images/submission-live-banner.jpg",
  SUCCESSFUL_PURCHASE: "/images/successful-purchase.png",
  INSUFFICIENT_DOLLAR: "/images/insufficient-dollar.png",
  INSUFFICIENT_GEM: "/images/insufficient-gem.png",
  INSUFFICIENT_TPPOG: "/images/insufficient-tppog.png",
  BUNDLE_LIMITATION: "/images/bundle-limitation.png",
  LAUGHING_PREDI: "/images/laughing-predi.png",
  SESSION_BANNER: "/images/session-banner.jpg",
  PREDIPIE: "/images/predipie.png",
  ARROW_TOP: "/images/arrow-top.png",
  CHECK_ICON: "/images/check-icon.png",
  PREDI_BOY_WIN: "/images/predi-boy-win.png",
  PREDI_BOY_WITH_TEN_THOUSAND: "/images/predi-boy-with-ten-thousand.png",
  PASSED_LEVELS: "/images/passed-levels.png",
  REVERSE_CLOUD: "/images/reverse-cloud.png",
  FIRST_PLACE: "/images/first-place.png",
  SECOND_PLACE: "/images/second-place.png",
  THIRD_PLACE: "/images/third-place.png",
  BEARDED_AVATAR: "/images/bearded-avatar.png",
  HAPPY_AVATAR: "/images/happy-avatar.png",
  SERIOUS_AVATAR: "/images/serious-avatar.png",
  ORANGE_OUTFIT_AVATAR: "/images/orange-outfit-avatar.png",
  ORANGE_HAIR_AVATAR: "/images/orange-hair-avatar.png",
  PRO_SUBMIT_BANNER: "/images/pro-submit-banner.jpeg",
  RETRY_BANNER: "/images/retry-banner.png",
  EMPTY_LEADER_BOARD: "/images/empty-leader-board.png",
  LEADER_BOARD_REWARDS_BANNER: "/images/leader-board-rewards-banner.jpg",
  LEADER_BOARD_REWARDS_BACKGROUND: "/images/leaderboard-reward-background.jpg",
  AIRDROP: "/images/airdrop.png",
  SIMPLE_WALLET: "/images/simple-wallet.png",
  POINTER_CURSOR: "/images/pointer-cursor.png",
  STACK_OF_COINS: "/images/stack-of-coins.png",
  DAILY_REWARD: "/images/daily-reward.png",
  SIMPLE_CHECK: "/images/simple-check.png",
  AD_ICON: "/images/ad.png",
  CONGRATULATIONS: "/images/congratulations.png",
  WELCOME_BONUS: "/images/welcome-bonus.png",
  DOGS_COIN: "/images/dogs-coin.png",
  HAMSTER_COIN: "/images/hamster-coin.png",
  NOT_COIN: "/images/not-coin.png",
  USDT_COIN: "/images/usdt-coin.png",
};

export const CACHE_KEYS = {
  INVENTORIES: "INVENTORIES",
  USER_FRIENDS: "USER_FRIENDS",
  USER_TASKS: "USER_TASKS",
  BUNDLE_CATEGORY: "BUNDLE_CATEGORY",
  BUNDLES: "BUNDLES",
  JOURNEYS: "JOURNEYS",
  WITHDRAWABLE_AMOUNT: "WITHDRAWABLE_AMOUNT",
  USER_INVENTORY: "USER_INVENTORY",
  WALLET_PAGE: "WALLET_PAGE",
  WALLET_PAGE_DOLLAR_LOG: "WALLET_PAGE_DOLLAR_LOG",
  DEPOSIT_WALLETS: "DEPOSIT_WALLETS",
  PRO_SUBMISSIONS: "PRO_SUBMISSIONS",
  PRO_STATS: "PRO_STATS",
  LEADER_BOARD: "LEADER_BOARD",
  PRO_SUBMISSIONS_REWARDS: "PRO_SUBMISSIONS_REWARDS",
  LEADER_BOARD_REWARDS: "LEADER_BOARD_REWARDS",
  DAILY_REWARDS: "DAILY_REWARDS",
  AD_TASK: "AD_TASK",
  TON_BALANCES: "TON_BALANCES",
};

export const disableAutoCompleteInputAttrs = {
  "aria-autocomplete": "both",
  "aria-haspopup": false,
  autoCapitalize: "off",
  autoComplete: "off",
  autoCorrect: "off",
  spellCheck: false,
  title: "Pesquisar",
} as const;

export const ERROR_STATUS = {
  NOT_FOUND: 404,
  UNAUTHORIZE: 401,
  BAD_REQUEST: 400,
};

export const ERROR_CODES = {
  INSUFFICIENT_BALANCE: "ERR_INSUFFICIENT_BALANCE",
  MAX_BALANCE_REACHED: "ERR_MAX_BALANCE_REACHED",
  TOKEN_DEPRECATED: "ERR_TOKEN_DEPRECATED",
  PREDICT_PRO_STEP_CONDITION_NOT_MATCHED:
    "ERR_PREDICT_PRO_STEP_CONDITION_NOT_MATCHED",
};

export const DEFAULT_OTP_LENGTH = 6;

export const FETCH_ONCHAIN_BALANCES_INTERVAL = 10000;

export const WEBSITE_URL = "https://predipie.com";

export const MAX_JOURNEY_ONBOARDING_COUNT = 2;

export const predictionResults = [
  {
    id: SubmissionResult.HOME,
    title: createElement(FormattedMessage, { id: "home" }),
  },
  {
    id: SubmissionResult.DRAW,
    title: createElement(FormattedMessage, { id: "draw" }),
  },
  {
    id: SubmissionResult.AWAY,
    title: createElement(FormattedMessage, { id: "away" }),
  },
];
