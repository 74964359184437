import { IMAGES } from "@/constants";
import { FC, useEffect, useState } from "react";
import Tab, { TabItem } from "@/components/common/tab";
import { FormattedMessage } from "react-intl";
import Gems from "@/components/common/gems";
import ShopTab from "./tabs/shop/content";
import InventoryTab from "./tabs/inventory/content";
import useBalance from "@/hooks/use-balance";
import { ShopContext } from "./context";

type TabValues = "shop" | "inventory";

const tabs: TabItem<TabValues>[] = [
  {
    value: "shop",
    title: <FormattedMessage id="shop" />,
    content: <ShopTab />,
  },
  {
    value: "inventory",
    title: <FormattedMessage id="inventory" />,
    content: <InventoryTab />,
  },
];

const ShopContent: FC = () => {
  const [tab, setTab] = useState<TabValues>(tabs[0].value);
  const { updateBalances } = useBalance();
  useEffect(() => {
    updateBalances();
  }, []);
  return (
    <div className="flex-1 flex flex-col">
      <div className="fixed top-0 left-0 right-0 z-[500] bg-blue-700">
        <div className="max-w-2xl w-full mx-auto p-4">
          <Gems
            cardProps={{
              badgeClassName: "bg-blue-850 border border-blue-shade-900",
            }}
          />
        </div>
      </div>
      <div
        className="w-full h-[5.75rem] bg-repeat-x bg-center bg-contain mt-14"
        style={{ backgroundImage: `url("${IMAGES.SHOP_BG}")` }}
      />
      <div className="w-full grow flex flex-col pb-6 max-w-lg mx-auto">
        <ShopContext.Provider value={{ goToShop: () => setTab("shop") }}>
          <Tab
            wrapperClassName="px-4"
            className="mb-4"
            tabs={tabs}
            value={tab}
            onValueChange={setTab}
          />
        </ShopContext.Provider>
      </div>
    </div>
  );
};

export default ShopContent;
