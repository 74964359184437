import { FC, useEffect, useRef, useState } from "react";
import MatchesContent, { MatchesContentRef } from "@/components/pages/matches";
import {
  ProSubmissionResponseType,
  StepResponseType,
  SubmissionResponseType,
} from "@/helpers/api/types/api-response";
import paths from "@/navigation/paths";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useApi from "@/helpers/api/hooks/use-api";
import { showErrorMessage } from "@/utils/string";
import toast from "react-hot-toast";
import { convertPredictionToString } from "@/components/common/match/utils";
import Dialog from "@/components/common/dialog";
import GiveawayContent from "@/components/common/giveaway-content";
import useBalance from "@/hooks/use-balance";

const PredictProMatchesPage: FC = () => {
  const [matchesData, setMatchesData] = useState<SubmissionResponseType>();
  const [matchesStep, setMatchesStep] = useState<StepResponseType>();
  const [matchesError, setMatchesError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [retryPurchaseData, setRetryPurchaseData] =
    useState<ProSubmissionResponseType["retryPurchase"]>();
  const matchesContentRef = useRef<MatchesContentRef>(null);
  const proDataRef =
    useRef<Pick<ProSubmissionResponseType, "proCount" | "proRetryCount">>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const onExpire = () => navigate(paths.predictPro.timeIsUp, { replace: true });
  const { submitPro, submitProMatches, claimReward } = useApi();
  const { updateBalances } = useBalance();
  const fetchMatches = () => {
    setMatchesError(null);
    return submitProMatches()
      .then((res) => {
        const {
          pro,
          proCount,
          proRetryCount,
          retryPurchase,
          ...submissionData
        } = res;
        setMatchesData(submissionData);
        matchesContentRef.current?.setPrediction(
          submissionData.items.map(({ prediction }) => ({
            isRegistered: !!prediction,
            result: convertPredictionToString(prediction),
          }))
        );
        proDataRef.current = { proCount, proRetryCount };
        setMatchesStep(pro);
        if (retryPurchase && searchParams.get("retry") === "true") {
          setRetryPurchaseData(retryPurchase);
        }
      })
      .catch(setMatchesError);
  };

  const onSubmit = () => {
    setLoading(true);
    return submitPro()
      .then(() => {
        navigate(paths.predictPro.submit, {
          replace: true,
          state: {
            proCount: proDataRef.current!.proCount,
            proRetryCount: (proDataRef.current!.proRetryCount || 0) + 1,
            maxRetryCount: matchesStep!.maxRetryCount,
            retryTime: Date.now() + matchesStep!.retryTime!,
            nextCost: {
              amount: matchesStep!.retryCost,
              asset: matchesStep!.retryCostAsset,
            },
          },
        });
      })
      .catch((err) =>
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        })
      )
      .finally(() => setLoading(false));
  };

  const claim = () => {
    if (!retryPurchaseData?.id) return;
    setLoading(true);
    claimReward(retryPurchaseData.id)
      .then(() => {
        updateBalances();
        setRetryPurchaseData(undefined);
        navigate(pathname, { replace: true });
      })
      .catch((err) =>
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchMatches();
  }, []);

  return (
    <>
      <Dialog
        seedClassName="p-4 pb-3 overflow-y-visible"
        open={!!retryPurchaseData}
        color="purple"
        withoutCloseIcon
        onClose={() => {}}
      >
        {!!retryPurchaseData?.bundle && (
          <GiveawayContent
            bundle={retryPurchaseData.bundle}
            onClaim={claim}
            loading={loading}
            amountCoefficient={retryPurchaseData.amount}
          />
        )}
      </Dialog>
      <MatchesContent
        matchesStep={matchesStep}
        matchesData={matchesData}
        setMatchesData={setMatchesData}
        matchesError={matchesError}
        onExpire={onExpire}
        onRetry={fetchMatches}
        loading={loading}
        onSubmit={onSubmit}
        ref={matchesContentRef}
      />
    </>
  );
};

export default PredictProMatchesPage;
