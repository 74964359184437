const endpoints = {
  auth: {
    login: "/api/v1/auth/telegram/login",
    refreshToken: "/api/v1/auth/refresh",
    me: "/api/v1/auth/me",
  },
  bundle: {
    bundleCategory: "/api/v1/bundle-categories",
    purchase: "/api/v1/purchases/purchaseBundle/:id",
    purchaseProduct: "/api/v1/purchases/product/:id",
  },
  journey: {
    index: "/api/v1/journeys",
  },
  submissions: {
    index: "/api/v1/submissions",
    step: "/api/v1/submissions/step",
    pay: "/api/v1/submissions/step/pay",
    predict: "/api/v1/submissions/step/prediction/:id",
    submit: "/api/v1/submissions/step/submit",
    currentStep: "/api/v1/submissions/current-step",
    stepId: "/api/v1/submissions/step/:id",
    submissionId: "/api/v1/submissions/:id",
    replaceSubmission: "/api/v1/submissions/step/replace/:id",
    product: "/api/v1/submissions/step/product/:id",
  },
  inventories: { index: "/api/v1/inventories" },
  balances: {
    index: "/api/v1/balances",
    assetBalance: "/api/v1/balances/assetId/:id",
  },
  transactions: {
    index: "/api/v1/transactions",
    withdraw: "/api/v1/transactions/withdraw",
    deposit: "/api/v1/transactions/deposit",
    dollarBalance: "/api/v1/balances/logs",
  },
  user: {
    friends: "/api/v1/referrals/me",
    nickname: "/api/v1/users/nickname/:nickname",
    postNickname: "/api/v1/users/nickname",
  },
  networkAsset: {
    index: "/api/v1/asset-networks",
  },
  quests: {
    userQuests: "/api/v1/user-quests",
    startQuest: "/api/v1/user-quests/start/:id",
    checkQuest: "/api/v1/user-quests/check/:id",
    dailyRewards: "/api/v1/user-quests/check-ins",
    userAds: "/api/v1/user-ads/check/1", //TODO id should be removed after backend fix
    verifyWatched: "/api/v1/user-ads",
  },
  purchase: {
    claimReward: "/api/v1/purchases/claimBundle/:id",
  },
  taps: {
    index: "/api/v1/taps",
    purchases: "/api/v1/taps/purchases/:id",
  },
  pro: {
    index: "/api/v1/submissions/pro",
    submit: "/api/v1/submissions/pro/submit",
    retry: "/api/v1/submissions/pro/retry",
    stats: "/api/v1/submissions/pro/stats",
    leaderBoard: "/api/v1/leader-boards/today",
    submissionRewards: "/api/v1/submissions/pro/first-unclaimed",
    leaderBoardRewards: "/api/v1/leader-boards/first-unclaimed",
  },
  sse: "/api/v1/sse",
  ton: {
    generatePayload: "/api/v1/ton/generate-payload",
    checkProof: "/api/v1/ton/check-proof",
    disconnect: "/api/v1/ton/disconnect-account",
    getAccountInfo: "/api/v1/ton/get-account-info",
    getBalances: "/api/v1/ton/balances",
  },
};

export default endpoints;
