import Header from "@/components/common/header";
import Image from "@/components/common/image";
import Button from "@/components/common/button";
import { CACHE_KEYS, IMAGES } from "@/constants";
import { FC, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Formik, Form, Field, FormikHelpers } from "formik";
import formikFields from "@/components/common/formik-fields";
import toast from "react-hot-toast";
import SuccessFulModal from "@/components/common/successful-modal";
import useApi from "@/helpers/api/hooks/use-api";
import { useNavigate } from "react-router-dom";
import { AssetUnit } from "@/global/enum";
import useValidation from "@/helpers/validations";
import { useData } from "@/store/store";
import useBalance from "@/hooks/use-balance";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { showErrorMessage } from "@/utils/string";

export interface WithdrawFormValues {
  walletAddress: string;
  amount: number | string;
}

const WithdrawContent: FC = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { withdraw, getAssetBalance } = useApi();
  const { balances } = useData();
  const navigate = useNavigate();
  const [successFullModal, setSuccessFulModal] = useState<boolean>(false);

  const { minWithdrawableBalance } = useBalance();

  const { withdrawValidationSchema } = useValidation();

  const {
    fetch,
    data: withdrawableAmount,
    error,
  } = useApiCache<string>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getAssetBalance(balances![AssetUnit.USD].asset.id)
          .then((res) => {
            setCache(res);
            resolve(res);
            return res;
          })
          .catch(reject)
      ),
    [CACHE_KEYS.WITHDRAWABLE_AMOUNT]
  );

  useEffect(() => {
    fetch();
  }, []);

  const handleSubmit = (
    values: WithdrawFormValues,
    { resetForm }: FormikHelpers<WithdrawFormValues>
  ) => {
    const payload = {
      walletAddress: values.walletAddress,
      amount: Number(values.amount),
    };

    withdraw(payload)
      .then(() => {
        setSuccessFulModal(true);
        resetForm();
      })
      .catch((err) => {
        toast.error(showErrorMessage(err.message), {
          position: "top-center",
          duration: 5000,
        });
      });
  };

  return (
    <>
      <SuccessFulModal
        imgSrc={IMAGES.WITHDRAW_WALLET}
        title="successFull-withdraw"
        description="successFull-withdraw-desc"
        open={successFullModal}
        onClose={() => setSuccessFulModal(false)}
        onSubmit={() => navigate(-1)}
        imageClassName="w-56 h-[8.5rem]"
      />
      <Header title={formatMessage({ id: "wallet-type-withdraw" })} />
      <div className="max-w-2xl w-full grow mx-auto px-4 pt-16 pb-6">
        <div className="flex justify-center">
          <Image
            src={IMAGES.WALLET}
            className="w-40 h-40"
            alt="wallet with coins and cash"
          />
        </div>
        <div className="mb-10 flex animate-fade-in">
          <p className="rounded-s-lg bg-blue-900 border border-blue-900 border-r-blue-600 border-b-blue-700 box-shadow-inner-0-2 shadow-blue-600 py-3 px-2 text-sm font-bold flex justify-center items-center grow">
            <FormattedMessage id="withdrawable-amount" />
          </p>
          <div className="flex justify-center items-center gap-1.5 rounded-e-lg bg-blue-900 border border-blue-900 border-b-blue-700 box-shadow-inner-0-2 shadow-blue-600 py-3 px-4 min-w-40 shrink-0">
            <Image
              src={error ? IMAGES.ALERT : IMAGES.COIN_ICON}
              className="h-6 w-6"
              alt="coin"
            />
            {error ? (
              <div onClick={() => fetch()} className="text-red-300">
                <FormattedMessage id="retry" />
              </div>
            ) : !withdrawableAmount ? (
              <div className="skeleton h-6 w-20 rounded-xl" />
            ) : (
              <p className="text-lg font-bold text-stroke-md-neutral-1000 text-shadow-0-1-neutral-1000 leading-4">
                <FormattedNumber
                  style="currency"
                  currency="USD"
                  value={Number(withdrawableAmount)}
                />
              </p>
            )}
          </div>
        </div>
        <Formik<WithdrawFormValues>
          initialValues={{
            walletAddress: "",
            amount: "",
          }}
          validationSchema={withdrawValidationSchema(
            +(withdrawableAmount || 0)
          )}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-4">
              <Field
                component={formikFields.TextField}
                name="walletAddress"
                placeholder={formatMessage({ id: "wallet-placeholder" })}
                label={formatMessage({ id: "wallet-address" })}
                className="animate-from-left"
              />
              <Field
                component={formikFields.TextField}
                name="network"
                value={formatMessage({ id: "ton" })}
                readOnly
                label={formatMessage({ id: "network" })}
                className="font-bold animate-from-left"
                helperText={formatMessage({ id: "withdraw-in-ton" })}
              />
              <Field
                component={formikFields.TextField}
                name="amount"
                placeholder={formatMessage(
                  { id: "amount-placeholder" },
                  {
                    minimum: formatNumber(minWithdrawableBalance, {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                    }),
                  }
                )}
                label={formatMessage({ id: "amount" })}
                className="animate-from-left"
                inputMode="numeric"
                type="number"
              />
              <div className="animate-from-bottom">
                <Button
                  type="submit"
                  color="secondary"
                  fullWidth
                  disabled={isSubmitting || !withdrawableAmount}
                >
                  <FormattedMessage id="wallet-type-withdraw" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default WithdrawContent;
