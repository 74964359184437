import { FC, useEffect } from "react";
import useApi from "@/helpers/api/hooks/use-api";
import { DollarBalanceLogType } from "@/helpers/api/types/api-response";
import useApiCache from "@/helpers/api/hooks/use-api-cache";
import { CACHE_KEYS } from "@/constants";
import Spinner from "@/components/common/spinner";
import { FormattedMessage } from "react-intl";
import FetchError from "@/components/common/fetch-error";
import NotFound from "./not-found";
import TransactionCard from "./transaction-card";
import useBalance from "@/hooks/use-balance";

const DollarLog: FC = () => {
  const { getDollarBalanceLog } = useApi();
  const { updateBalances } = useBalance();

  const { fetch, data, error } = useApiCache<DollarBalanceLogType[]>(
    ({ setCache }) =>
      new Promise((resolve, reject) =>
        getDollarBalanceLog({ limit: 50 })
          .then((res) => {
            setCache(res.data);
            updateBalances();
            resolve(res.data);
          })
          .catch(reject)
      ),
    [CACHE_KEYS.WALLET_PAGE_DOLLAR_LOG]
  );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="flex flex-col gap-4 grow mt-4">
      <p className="px-2 text-2xl font-bold animate-fade-in">
        <FormattedMessage id="transaction-history" />
      </p>
      <div className="flex flex-col gap-2 grow">
        {error ? (
          <FetchError
            onRetry={fetch}
            message={error.message}
            className="grow"
          />
        ) : data ? (
          data.length === 0 ? (
            <NotFound />
          ) : (
            data.map(({ amount, createdAt, type, id }) => (
              <TransactionCard
                key={id}
                amount={amount}
                createdAt={createdAt}
                type={type}
              />
            ))
          )
        ) : (
          <div className="grow py-6 flex items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default DollarLog;
