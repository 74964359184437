import { FC } from "react";
import { FormattedMessage } from "react-intl";
import LeaderBoardReward from "@/components/pages/predict-pro/tabs/leader-board/leaderboard-rewards";

interface PredictProDescriptionProps {
  firstTimeUser: boolean;
}
const PredictProDescription: FC<PredictProDescriptionProps> = ({
  firstTimeUser = true,
}) => {
  return (
    <div className="overflow-x-hidden pb-56 w-full">
      <div className="bg-blue-850 py-5 relative">
        <div className="max-w-2xl mx-auto -mb-48 px-4 z-10 relative flex flex-col gap-4 items-center justify-center">
          <div className="text-center font-bold text-shadow-0-3-blue-900 text-2xl text-stroke-md-blue-900 animate-from-top">
            <FormattedMessage id="predict-pro" />
          </div>
          {firstTimeUser ? (
            <div className="text-base flex-1 animate-fade-in">
              <FormattedMessage id="predict-pro-information" />
            </div>
          ) : (
            <LeaderBoardReward />
          )}
        </div>
        <div className="rounded-b-[50%] bg-blue-850 absolute top-full -start-[9.375rem] -end-[9.375rem] h-[13.125rem]" />
      </div>
    </div>
  );
};

export default PredictProDescription;
